import * as React from 'react'
import { graphql } from 'gatsby'

const HomePage = ({data}) => {
  return (
    
    <main >
        <title>French 2021</title>
        <h1>French 2021 Beginning class<br/><span role="img" aria-label="Party popper emojis">🎉🎉🎉🎉🎉🎉🎉🎉🎉🎉</span>
        </h1>
        <ul>
          {data.allFile.edges.map(file => (
            <li><a href={file.node.publicURL}>{file.node.name}</a></li>
          ))}
        </ul>
    </main>
  )
}
export const query = graphql`
  query MyQuery {
    allFile {
      edges {
        node {
          id
          publicURL
          name
        }
      }
    }
  }
`
export default HomePage

